<template>
  <div class="item_list">
    <van-nav-bar
      title="详情"
      left-arrow
      fixed
      placeholder
      safe-area-inset-top
      @click-left="navBack"
    />
    <van-cell-group class="info_main">
      <van-cell :title="detailData.type" />
      <van-cell
        v-if="display_money"
        :title="`￥${detailData.money}`"
        size="large"
      />
      <van-cell title="申请时间" :value="detailData.createtime" />
      <van-cell title="团建时间" :value="detailData.teamtime" />
      <van-cell title="团建人数" :value="`${detailData.people}人`" />
      <van-cell title="团建地点" :value="detailData.place" />
      <van-cell title="详细地址" :value="detailData.address" />
    </van-cell-group>
    <van-cell-group class="info_comment">
      <van-field
        v-model="comment"
        rows="3"
        autosize
        readonly
        label="备注"
        type="textarea"
        placeholder="没有留下任何信息"
      />
    </van-cell-group>
    <van-cell-group v-if="status === 0" class="info_btn">
      <van-button block disabled type="default">
        审核中
      </van-button>
    </van-cell-group>
    <van-cell-group v-if="status === 8" class="info_btn">
      <van-button block type="default">
        审核失败
      </van-button>
    </van-cell-group>
    <van-cell-group v-if="status === 1" class="info_btn">
      <van-button block type="primary" @click="onSubmitInvoice">
        申请金额
      </van-button>
    </van-cell-group>
    <van-cell-group v-if="status === 9" class="info_btn">
      <van-button block type="primary" @click="onSubmitInvoice">
        资料审核失败，请点击重新提交资料
      </van-button>
    </van-cell-group>
    <van-cell-group v-if="display_pic" class="info_comment">
      <van-cell title="团建照片" />
      <van-grid :border="false" :column-num="2">
        <van-grid-item v-for="(imgitem, imgIndex) in pic" :key="imgIndex">
          <van-image
            class="info_comment_imgs"
            fit="cover"
            :src="imgitem"
            lazy-load
            @click="imageClick(imgitem)"
          >
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </van-grid-item>
      </van-grid>
    </van-cell-group>
    <van-cell-group v-if="display_pic" class="info_comment">
      <van-cell title="发票照片" />
      <van-grid :border="false" :column-num="2">
        <van-grid-item v-for="(imgitem, imgIndex) in invoice" :key="imgIndex">
          <van-image
            class="info_comment_imgs"
            :src="imgitem"
            fit="cover"
            lazy-load
            @click="imageClick(imgitem)"
          >
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </van-grid-item>
      </van-grid>
    </van-cell-group>
    <van-cell-group v-if="status === 3" class="info_btn mb-60i">
      <van-button block disabled type="default">
        已完成
      </van-button>
    </van-cell-group>
    <van-cell-group v-if="status === 2" class="info_btn mb-60i">
      <van-button block disabled type="default">
        资料已提交，正在审核
      </van-button>
    </van-cell-group>
  </div>
</template>

<script>
import {
  List,
  Cell,
  CellGroup,
  Field,
  Form,
  Grid,
  Toast,
  GridItem,
  Image,
  NavBar,
  Button,
  Loading,
  ImagePreview
} from 'vant'
// eslint-disable-next-line no-unused-vars
import { teamBuildLogDetail, teamBuildLogAgain } from '@/api/home'
// import { assetspath } from '@/common/constants'
import { mapState } from 'vuex'
export default {
  components: {
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [Loading.name]: Loading,
    [List.name]: List,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Form.name]: Form,
    [Toast.name]: Toast,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [ImagePreview.name]: ImagePreview,
    [Image.name]: Image,
    [CellGroup.name]: CellGroup
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      transitionName: 'van-slide-right',
      status: 0,
      detailData: {
        createtime: '2021-01-18 17:37',
        actual_money: '0.00',
        address: '牛肉店',
        isdel: 0,
        money: '0.00',
        notice: null,
        people: 3,
        place: '广西壮族自治区 南宁市 西乡塘区',
        status: 1,
        teamtime: '2021-01-18',
        type: '打球',
        imgs: [],
        bills: []
      },
      // pic: [
      //   'https://img.yzcdn.cn/vant/apple-1.jpg',
      //   'https://s3.ax1x.com/2021/01/13/sNRSrd.png',
      //   'https://img.yzcdn.cn/vant/cat.jpeg',
      //   'https://img.yzcdn.cn/vant/apple-2.jpg'
      // ],
      // invoice: [
      //   'https://img.yzcdn.cn/vant/apple-1.jpg',
      //   'https://s3.ax1x.com/2021/01/13/sNRSrd.png',
      //   'https://img.yzcdn.cn/vant/apple-3.jpg'
      // ]
      pic: [],
      invoice: []
    }
  },
  computed: {
    ...mapState(['UserProfile']),
    comment: {
      get() {
        return this.detailData.notice ? this.detailData.notice : ''
      },
      set(val) {
        this.msg = val
      }
    },
    display_money() {
      return [2, 3, 9].includes(this.status) ? true : false
    },
    display_pic() {
      return [2, 3, 9].includes(this.status) ? true : false
    }
  },
  created() {},
  mounted() {
    this.initData()
  },
  activated() {
    console.log('i detail activated')
    console.log(this.detail)
  },
  methods: {
    initData() {
      console.info('initdata,id:' + this.id)
      teamBuildLogDetail({ id: this.id })
        .then(res => {
          console.info(res)
          if (res.status === 0) throw res.msg
          this.detailData = { ...this.detailData, ...res.data }
          this.status = res.data.status
          console.info('status: ' + this.status)
          if (this.detailData.imgs.length > 0) {
            this.detailData.imgs.forEach(element => {
              this.pic.push(element)
            })
          }
          if (this.detailData.bills.length > 0) {
            this.detailData.bills.forEach(element => {
              this.invoice.push(element)
            })
          }
        })
        .catch(err => {
          console.error(err)
          Toast.fail('加载失败：' + err)
        })
    },
    navBack() {
      this.$router.go(-1)
    },
    onPpReset() {
      this.popupshow = false
      console.log('onPpReset')
    },
    onSubmit() {
      console.log('onSubmit')
    },
    onCancel() {
      console.log('onCancel')
      this.$router.go(-1)
    },
    onConfirm() {
      console.log('onConfirm')
    },
    click_add() {
      console.log('click_add')
    },
    onSubmitInvoice() {
      console.log('onSubmitInvoice')
      // this.$router.push({ name: 'costEntity', params: { id: '133' } })
      this.$router.push({
        name: 'costEntity',
        // query: { actualMoney: `${this.detailData.actual_money}` },
        params: {
          id: `${this.id}`,
          detail: this.detail,
          // actualMoney: `${this.UserProfile.team_building_fee}`
          actualMoney: this.UserProfile.team_building_fee.toFixed(2)
        }
      })
    },
    imageClick(event) {
      console.log('imageClick', event)
      ImagePreview([event])
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/style/global.less';
.mt-10 {
  margin-top: 10px;
}
.m-16 {
  margin: 16px;
}
.mt-30 {
  margin-top: 30px;
}
.mb-60i {
  margin-bottom: 60px !important;
}
.item_list {
  height: auto;
  background-color: #f5f5f5;
}
.info_main {
  margin: 10px 16px;
  overflow: hidden;
  border-radius: 6px;
}
.info_comment {
  margin: 10px 16px;
  overflow: hidden;
  border-radius: 6px;
}
.info_btn {
  margin: 10px 16px;
  border-radius: 6px;
}
.pic_zone {
  margin: 14px;
}
.info_comment_imgs {
  width: 153px;
  height: 110px;
  border: 1px dashed #999999;
}
</style>
